
// @import "./css-libs/aos";
// @import "./css-libs/owl.theme.default.min";
// @import "./css-libs/owl.carousel.min";

@import "./bootstrap4/bootstrap-reboot.scss";
// @import "./bootstrap4/bootstrap-grid.scss";
@import "./bootstrap4/bootstrap.scss";

@import url('https://fonts.googleapis.com/css?family=Lato|Source+Sans+Pro|Tajawal:500');


$font-heading: "Source Sans Pro", 'Tajawal', Arial;
$font-Paragraph: "Lato", 'Tajawal', Arial;

body {
    font-family: $font-Paragraph;
}


// language 

.text-start{
    text-align: left !important;
    text-align: start !important;
}

.arabic{
    direction: rtl;
    text-align: right;

    .text-start{
        text-align: start !important;
        text-align: right !important;
    }

    // font-weight: 600;
    font-size: 1.1 em;
    #foot{
        font-size: 0.01 em;
    }

    .owl-stage-outer{
        direction: ltr;
    }
}

.rtl {
    direction: RTL;
}

.ltr {
    direction: LTR;
    text-align: left;
}

// body stuff

p{
    font-family:$font-Paragraph;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-heading;
	color: #3e3e3e;
	margin: 0 0 0.75em 0;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	text-decoration: none;
	color: inherit;
}
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600;
}
h1 {
	font-size: 3.25em;
	letter-spacing: -0.025em;
	font-weight: 300;
	strong {
		font-weight: 700;
	}
}
h2 {
	font-size: 2em;
	letter-spacing: -0.015em;
}
h3 {
	font-size: 1.5em;
	letter-spacing: -0.015em;
}
em,
i {
	font-style: italic;
}
br.clear {
	clear: both;
}
hr {
	border: 0;
	border-top: solid 1px #444;
	border-top-color: rgba(0, 0, 0, 0.35);
	box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
	height: 1px;
	margin: 3em 0;
}


header {
	margin: 0 0 1em 0;
	> p {
		margin: 0;
	}
}
footer {
	> p {
		font-size: 1.25em;
	}
}

/* Form */
form {
	label {
		color: #bbb;
		font-weight: 700;
		display: block;
		margin: 0 0 0.5em 0;
	}
	table tr td:first-child {
		width: 1%;
		white-space: nowrap;
	}
	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="tel"],
	select,
	textarea {
		width: 100%;
		resize: none;
		margin-bottom: 10px;
		-webkit-appearance: none;
		display: block;
		border: 0;
		padding: 0.6em;
		font-size: 1em;
		border-radius: 8px;
		border: solid 1px #ddd;
		background: #fff;
		color: #3e3e3e;
		box-shadow: inset 0px 2px 3px 1px rgba(0, 0, 0, 0.05),
			0px 1px 0px 0px rgba(255, 255, 255, 0.025);
		&:focus {
			outline: none;
			background: #fafafa;
			box-shadow: inset 0px 2px 5px 0px rgba(0, 0, 0, 0.05),
				0px 1px 0px 0px rgba(255, 255, 255, 0.025),
				inset 0px 0px 6px 2px #43bff0;
		}
	}
	textarea {
		height: 15em;
	}
	.actions {
		&:last-child {
			margin-bottom: 0;
		}
	}
	::-webkit-input-placeholder {
		color: #bbb !important;
	}
	:-moz-placeholder {
		color: #bbb !important;
	}
	::-moz-placeholder {
		color: #bbb !important;
	}
	:-ms-input-placeholder {
		color: #bbb !important;
	}
	::-moz-focus-inner {
		border: 0;
	}
}
/* Tables */
table {
	width: 100%;
	&.default {
		width: 100%;
		tr {
			border-top: solid 1px #eee;
			&:first-child {
				border-top: 0;
			}
		}
		td {
			padding: 0.5em 1em 0.5em 1em;
		}
		th {
			text-align: left;
			padding: 0.5em 1em 0.5em 1em;
			font-weight: 600;
			margin: 0 0 1em 0;
		}
		thead {
			background: #4f4f4f;
			color: #fff;
		}
	}
}


/* Button */
.glow-button {
	background-image: 'url("/images/bg.png")',
		"linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2))";
	-webkit-appearance: none;
	position: relative;
	display: inline-block;
	color: #fff !important;
	text-decoration: none;
	font-weight: 700;
	border: 0;
	outline: 0;
	cursor: pointer;
	border-radius: 8px;
    text-shadow: 0px 0px 1px rgba(0,0,0,0.5);
	overflow: hidden;
	box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.5),
		inset 0px 2px 1px 0px rgba(255, 255, 255, 0.75);
	background-color: #0c385a;
	padding: 1em 1em;;
	font-size: 1.1em;
	// max-width: 24em;
	&:hover {
		background-color: #0d7bcf;
		color: #fff !important;
	}
	&:active {
		background-color: #0d7bcf;
		top: 1px;
	}
	&.large {
		font-size: 1.5em;
		letter-spacing: -0.025em;
	}
	&.full-width {
		width: 100%;
		font-size: 1.3em;
		margin: 0 auto;
	}
	&.alt {
		background-color: #444;
		box-shadow: inset 0px 0px 0px 1px #242424,
			inset 0px 2px 1px 0px rgba(255, 255, 255, 0.2);
		&:hover {
			background-color: #4f4f4f;
			color: #fff !important;
		}
		&:active {
			background-color: #3f3f3f;
		}
    }
    
    &.glow-dark{
        background-color: #0d7bcf;
        &:hover {
            background-color: #0d7bcf;
        }
        &:active {
            background-color: #0d7bcf;
        }
    }
}

/* List */
ul {
	list-style: disc;
	padding-left: 1em;
	li {
		padding-left: 0.5em;
	}
}
ol {
	list-style: decimal;
	padding-left: 1.25em;
	li {
		padding-left: 0.25em;
	}
}
/* Social */
ul.social {
	cursor: default;
	margin: 0;
	list-style: none;
	li {
		position: relative;
		display: inline-block;
		margin: 0.25em;
		top: 0;
		padding-left: 0;
		a {
            text-decoration: none;
			transition: top 0.2s ease-in-out;
			display: block;
			width: 48px;
			height: 48px;
			border-radius: 6px;
			top: 0;
			position: relative;
			&:before {
				background-image: url("/images/bg.png"),
					linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
				transition: background-color 0.2s ease-in-out;
				border-radius: 6px;
				box-shadow: inset 0px 0px 0px 1px #282828,
					inset 0px 2px 1px 0px rgba(255, 255, 255, 0.1);
				color: white;
				display: block;
				font-size: 26px;
				height: 48px;
				line-height: 48px;
				text-align: center;
				outline: 0;
				overflow: hidden;
				text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.1);
				width: 48px;
			}
			&.fa-twitter {
				background-color: #2daae4;
			}
			&.fa-facebook {
				background-color: #3c5a98;
			}
			&.fa-dribbble {
				background-color: #c4376b;
			}
			&.fa-linkedin {
				background-color: #006599;
			}
			&.fa-tumblr {
				background-color: #51718a;
			}
			&.fa-google-plus {
				background-color: #da2713;
			}
			&.fa-github {
				background-color: #fafafa;
			}
			&.fa-rss {
				background-color: #f2600b;
			}
			&.fa-instagram {
				background-color: #e0d7c8;
			}
			&.fa-foursquare {
				background-color: #39a3d4;
			}
			&.fa-skype {
				background-color: #10bef1;
			}
			&.fa-soundcloud {
				background-color: #fe5419;
			}
			&.fa-youtube {
				background-color: #bf2e25;
			}
			&.fa-blogger {
				background-color: #ff6501;
			}
			&.fa-flickr {
				background-color: #0062db;
			}
			&.fa-vimeo {
				background-color: #4c8ab0;
			}
			&:hover {
				box-shadow: 0px 0px 19px 0px rgba(255, 255, 255, 1);
				transition: box-shadow 0.2s ease-in-out;
				top: -5px;
				&::after {
				}
			}
		}
	}
}

/* Actions */
ul.actions {
	list-style: none;
	padding-left: 0;
	li {
		display: inline-block;
		margin: 0 0 0 1em;
		padding-left: 0;
		&:first-child {
			margin-left: 0;
		}
	}
}

/* Box */
.box {
	background: #fff;
	box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.15),
		0px 2px 3px 0px rgba(0, 0, 0, 0.1);
	text-align: center;
	&.style1 {
		padding: 3em 2em 3.5em 2em;
	}
	&.style2 {
		.image {
			position: relative;
			left: 2em;
			top: 2em;
			margin: -4em 0 4em -4em;
			width: auto;
		}
	}
	&.style3 {
		.image {
			position: relative;
			left: 2em;
			top: 2em;
			margin: -4em 0 4em -4em;
			width: auto;
		}
	}
	&.style5 {
		padding: 0.8em;
		text-align: center;
		@include media-breakpoint-up(xl) {
			height: 160px;
		}
		@include media-breakpoint-down(xl) {
			height: 170px;
		}
		@include media-breakpoint-down(lg) {
			height: 230px;
		}
		@include media-breakpoint-down(md) {
			height: 130px;
		}
		box-sizing: border-box;
		span {
			height: 50%;
			display: block;
			// background: #0062db;
			vertical-align: middle;
			line-height: 1.2em;
			> span {
				line-height: 1.2em;
				transform: translatey(50%);
			}
		}
		.image {
			height: 100%;
			width: auto;
		}
	}
}

/* Icons */
.icon {
	text-decoration: none;
	&:before {
		display: inline-block;
		font-size: 1.25em;
		text-decoration: none;
		font-style: normal;
		font-weight: normal;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	> .label {
		display: none;
	}
	&.featured {
		color: #ea8a95;
		display: block;
		margin: 0 0 1.5em 0;
		cursor: default;
		&:before {
			font-size: 6em;
		}
	}
}

/* Wrappers */
.wrapper {
    //background-image: url("/images/bg.png");
    //background-color: #efefef;
    background-color: #fff;
	&.style1 {
		background-image: none;
		background-color: #fff;
		@include media-breakpoint-down(lg) {
			padding: 3em 0 3em 0;
		}
		@include media-breakpoint-up(lg) {
			padding: 3vh 0 5vh 0;
		}
    }
    &.style2{
        background-image: url("/images/bg.png");
        background-color: #efefef;
    }
	&.style4 {
		background: radial-gradient(#1a3a3a 35%, #303030 90%);
		color: #fff;
        text-shadow: 0px 0px 0px #181818;
		padding: 4em 0 4em 0;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: #fff;
		}
		form {
			input[type="text"],
			input[type="password"],
			select,
			textarea {
				border: none;
				background: #282828;
				&:focus {
					background: #252525;
				}
			}
		}
	}
	&.style5 {
		background: radial-gradient(#331a1a 35%, #303030 90%);
		color: #999;
		text-shadow: -1px -1px 0px #181818;
		padding: 5em 0 5em 0;
		h1,
		h2,
		h4,
		h5,
		h6 {
			color: #fff;
		}
		h3 {
			font-weight: 400;
			color: #fff;
			line-height: 1.3em;
			text-align: justify;
			@include media-breakpoint-down(lg) {
				padding: 2em 2em 0 2em;
				text-align: center;
				font-size: 1.2em;
			}
			@include media-breakpoint-up(lg) {
				padding: 0.3em 1em 0 1em;
                font-size: 1.3em;
                text-align: left;
				text-align: start;
			}
		}
	}
	&.style6 {
        background: white;
		color: #000;
		// text-shadow: -1px -1px 0px #181818;
		h1,
		h2,
		h4,
		h5,
		h6 {
		}
		h3 {
			line-height: 1.3em;
			text-align: justify;
			@include media-breakpoint-down(lg) {
				padding: 2em 3em 0 3em;
				text-align: center;
				font-size: 1.2em;
			}
			@include media-breakpoint-up(lg) {
				padding: 0.3em 1em 0 1em;
                font-size: 1.6em;
                text-align: left;
				text-align: start;
			}
		}
	}
}



#footer {
	footer {
		font-size: 0.9em;
    }
}
/* Copyright */
.location {


	&.style2 {
        direction: ltr;
		color: #fff;
		font-size: 1em;
		line-height: 1em;
		list-style: none;
		padding-left: 0;
        margin-bottom: 0;
        text-align: left;
		text-align: start;
		p {
			line-height: 1.3em;
		}
		strong {
			color: #fff;
		}
		.contact-box {
			@include media-breakpoint-up(md) {
				border-left: solid 1px rgba(0, 0, 0, 0.5);
				box-shadow: -1px 0px 0px 0px rgba(255, 255, 255, 0.1);
				padding: 0 0 0 1em;
			}
			@include media-breakpoint-down(sm) {
				min-width: 360px;
				border-top: solid 1px #fff; // box-shadow: -1px 0px 0px 0px rgba(255, 255, 255, 0.1);
				padding: 1em 0 0 0;
			}
			display: inline-block;
			// box-shadow: -1px 0px 0px 0px rgba(255, 255, 255, 0.1);
			&:first-child {
				border: 0;
				box-shadow: none;
				padding-left: 0;
				margin-left: 0;
			}
		}
	}
}

.copyright{
    p{
        margin: 0;
    }
    .copyright-subtext{
        font-size: 0.8em;
        color: #bdbdbd;
    }
}
/* ********************* */
/* my styles additions */
/* ********************* */
.centered {
	margin-left: auto;
	margin-right: auto;
}
.pushed-left {
	margin-right: auto;
}
.pushed-right {
	margin-left: auto;
}
#map-abudhabi,
#map-dubai {
	@include media-breakpoint-down(sm) {
		display: none;
	}
	height: 400px;
	width: 100%;
}
/* Nav Bar */
$menu-mobile-height: 60px;
$menu-desktop-height: 100px;
body {
	margin-top: $menu-desktop-height;
	@include media-breakpoint-down(sm) {
		margin-top: $menu-mobile-height;
	}
}
.topnav {
	opacity: 0.985;
	z-index: 10000;
	position: fixed;
	width: 100%;
	overflow: hidden;
	background-color: #fff;
	height: $menu-desktop-height;
    top: 0px;
    
    @include media-breakpoint-up(lg) {
        display:flex;
        justify-content: center;
    }

	.head-logo {
		padding: 25px;

		padding-left: 10%;
		height: $menu-desktop-height;
	}
	img {
		// center-stuff
		float: left;
	}
	a {
		transition: all 0.2s ease-in-out;
		&:hover:not(:first-child) {
            &:last-child{
                @include media-breakpoint-down(md) {
                    background: #b2e9ff;
                    border-bottom: 0px;
                }
            }
			color: #43bff0 !important;
			border-bottom: 8px #43bff0 solid;
		}
		&:not(:first-of-type) {
			display: flex;
			align-items: center;
			justify-content: center;
			vertical-align: middle;
			height: inherit;
			float: left;
			color: #333;
			font-weight: 600;
			text-align: center;
			padding: 14px 16px;
			text-decoration: none;
			font-size: 17px;
		}
	}
	.icon {
		display: none;
	}
	@include media-breakpoint-down(md) {
		height: $menu-mobile-height;
		.head-logo {
			height: $menu-mobile-height;
			padding: 7px;
			padding-left: 3%;
		}
		a {
			&.icon {
				float: right;
				display: block;
			}
			&:not(:first-child) {
				display: none;
			}
			&:first-child {
				// display: none;
			}
			&:last-child {
				display: block;
				max-height: 100%;
				img {
					height: $menu-mobile-height;
					max-height: 100%;
				}
			}
			&:hover {
				border-bottom: 0px #43bff0 solid;
			}
		}
		&.responsive {
			height: auto;
			position: Fixed;
			.head-logo {
				// float: none;
				display: block;
			}
			.icon {
				position: absolute;
				right: 0;
				top: 0;
			}
			a {
				height: $menu-mobile-height;
				float: none;
				display: block;
                text-align: left;
                text-align: start;
			}
		}
	}
}
/* insurance partners slider */
.owl-stage {
	margin: 1em;
	.owl-item {
		background-color: #fff;
		border: 1px #ebebeb solid;
		border-radius: 3px;
        padding: 10px;
        box-shadow: 0px 0px 9px -4px rgba(0,0,0,0.75);
		img {
			border-radius: 20px;
		}
	}
}
.owl-theme .owl-dots .owl-dot {
	box-shadow: none;
}
/* insurance partners slider */
.mybackground {
    transition: background-image 1s ease-in-out;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    /* height */
    height: 45vh; 
    @include media-breakpoint-down(md) {
        height: 35vh;
        //background-size: auto;
        position: sticky;
        top: 0vh;
        z-index: -10;
    }
    // background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &.first-image{
        background-image: url("/images/intro_01.jpg"); 
    }
    &.second-image{
        background-image: url("/images/intro_02.jpg"); 
    }
    &.third-image{
        background-image: url("/images/intro_03.jpg"); 
    }
    &.fourth-image{
        background-image: url("/images/intro_04.jpg"); 
    }
}

// text-center
.text-center {
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column; /* column | row */
}
.text-style {
	&.style1 {
		color: #2e2e2e;
		h3 {
			color: black;
			line-height: 0.8em;
			margin: 0 0 0 0;
			@include media-breakpoint-down(lg) {
				font-size: 1.7em;
			}
		}
		h1 {
			color: black;
			line-height: 0.9em;
			margin: 0 0 0 0;
			@include media-breakpoint-down(xl) {

				font-size: 2.6em;
			}
			@include media-breakpoint-up(xl) {

				font-size: 2.9em;
			}
		}
		h5 {
			font-size: 1em;
			font-weight: 600;
			margin: 0 10px 10px 0;
		}
		h4 {
			font-weight: 500;
			color: black;
			@include media-breakpoint-up(xl) {
				font-size: 1.3em;
                line-height: 1.3em;
                text-align: left;
				text-align: start;
			}
			@include media-breakpoint-down(lg) {
                text-align: left;
				text-align: start;
				font-size: 1.2em;
				line-height: 1.2em;
			}
			@include media-breakpoint-down(md) {
				text-align: center;
				font-size: 1em;
				line-height: 1.2em;
				font-weight: 600;
			}
		}
	}
	&.style2 {
		h2 {
			margin-bottom: 0.3em;
		}
		h4 {
            line-height: 1.3em;
            text-align: left;
            text-align: start;
            @include media-breakpoint-up(lg) {
                font-size: 1.1em;
                text-align: left;
				text-align: start;
			}
			@include media-breakpoint-down(lg) {
                // padding: 2em 2em 0 2em;
                text-align: left;
				text-align: start;
				font-size: 0.95em;
			}
			@include media-breakpoint-down(md) {
                font-size: 0.85em;
                text-align: left;
				text-align: start;
			}
		}
	}
}
// equal width cards
.row {
	&.display-flex {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 40px;
	}
}
.equal-height {
	height: 100%;
}
// small font on phone screen for paraghragh
.cards-font {
	@include media-breakpoint-up(lg) {
		font-size: 0.85em;
        line-height: 1;
        text-align: left;
		text-align: start;
	}
	@include media-breakpoint-down(lg) {
		text-align: center;
		font-size: 0.8em;
		line-height: 1;
	}
	@include media-breakpoint-down(md) {
		font-size: 0.65em;
        line-height: 1;
        text-align: left;
		text-align: start;
		h3 {
			padding-bottom: 8px;
			margin: 0;
		}
	}
}

.white-background {
    // line-height: 1.3;
	padding: 2em;
	padding-left: 1em;
	background-color: rgba(255, 255, 255, 0.93);
	border-radius: 3px;
	opacity: 1;
}

.my-card {
	transition: all 0.2s ease-in-out;
	margin-bottom: 25px;
	&:hover {
		transform: scale(1.05);
	}
}

.intro-mobile {
	// background: #0c3958;
	text-align: center;
	padding-top: 2em;
	font-size: 100%;
	h1 {
        color: #0c3958;
        font-weight: 600;
	}

	// line-height: 1;
	font-weight: 500;
}

#careers {
	background-image: url(/images/bg.png);
	background-color: #efefef;
	.careers-holder {
		font-size: 85%;
		padding: 1em;
		background-color: rgba(255, 255, 255, 0.93);
		border-radius: 3px;
		opacity: 1;
	}
	h2 {
		font-weight: 500;
		margin-top: 1rem;
	}
}

#contact {
	background-image: url(images/bg.png);
    background-color: #efefef;
    
    a{
        unicode-bidi: embed;
        direction: ltr;
        color:#048fd4;
    }
}

// possible mac fix?
.row:before, .row:after {
    display: flex !important;
}

#ins-forms{

    table{
        border-collapse: collapse;
        th{
            background: #34495e;
            color: white;
            border-top: 1px solid rgba(0,0,0,0.1);
            border-bottom: 1px solid rgba(0,0,0,0.1);
            padding: 8px;
            font-size: 2em;
        }
        td{
            border-top: 1px solid rgba(0,0,0,0.1);
            border-bottom: 1px solid rgba(0,0,0,0.1);
            padding: 8px;
            font-size: 1.2em;
            font-weight: 600;
        }
    }

}

.upload-helper{
    color: #bd0101;
    font-weight: 600;
}

.partner-box{
    padding:1em;
    filter: grayscale(1);
    transition: all 0.15s ease-in-out;
    @include media-breakpoint-down(md) {
        filter: grayscale(0);
    }
    &:hover{
        filter: grayscale(0);
        transform: scale(1.2);
    }
}

@keyframes iconPopping {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.15);
    }
    40% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
}

#primecompare{

    background: white;
    @include media-breakpoint-up(md) {
        background: linear-gradient(90deg, #d0ccff 0%, #d0ccff 45%, #fff 45%, #fff 100%);
    }


    .fas{
        font-size: 4em;
        text-shadow: 0px 0px 1px #707070;
        animation: iconPopping 3s infinite;

        &.fas-red{
            color: red;
            animation-delay: 1s
        }

        &.fas-blue{
            color: blue;
            animation-delay: 2s
        }

        &.fas-orange{
            color: orange;
            animation-delay: 3s
        }




    }

    img{
        max-height: 80px;
        transition: all 0.2s ease-in-out;
        &:hover{
            transform: scale(1.05);
        }
    }

}

.slick-arrow{
    &:before{
        font-size: xx-large !important;
        color:black !important;
    }
}

.slick-dots li button:before {
    font-size:large !important;
}